<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row">
                <div class="col-12">
                  <h4> User Password and Security Setting</h4>
                </div>

              </div>
            </div>
            <!--                    <div academic-class="row">-->
            <!--                        <div academic-class="col-4 form-group">-->
            <!--                            <label for>First Name</label>-->
            <!--                            <input type="text" academic-class="form-control border border-dark" placeholder="First Name" v-model="user.first_name"/>-->
            <!--                            <span v-if="$v.user.first_name.$error" academic-class="text-danger">Enter valid phone no</span>-->
            <!--                        </div>-->
            <!--                        <div academic-class="col-4 form-group">-->
            <!--                            <label for>Middle Name</label>-->
            <!--                            <input-->
            <!--                                    type="text"-->
            <!--                                    academic-class="form-control border border-dark"-->
            <!--                                    placeholder="Middle Name"-->
            <!--                                    v-model="user.middle_name"-->
            <!--                            />-->
            <!--                            &lt;!&ndash; <span v-if="$v.user.tagline.$error" academic-class="text-danger">Enter valid phone no</span> &ndash;&gt;-->
            <!--                        </div>-->
            <!--                        <div academic-class="col-4 form-group">-->
            <!--                            <label for>Last Name</label>-->
            <!--                            <input-->
            <!--                                    type="text"-->
            <!--                                    academic-class="form-control border border-dark"-->
            <!--                                    placeholder="Last Name"-->
            <!--                                    v-model="user.last_name"-->
            <!--                            />-->
            <!--                            &lt;!&ndash; <span v-if="$v.user.website.$error" academic-class="text-danger">Phone is required.</span> &ndash;&gt;-->
            <!--                        </div>-->
            <!--                        <div academic-class="col-4 form-group">-->
            <!--                            <label for>Email</label>-->
            <!--                            <input type="email" academic-class="form-control border border-dark" placeholder="Email" v-model="user.email"/>-->
            <!--                            <span v-if="$v.user.email.$error" academic-class="text-danger">please provide valid email</span>-->
            <!--                        </div>-->
            <!--                        <div academic-class="col-4 form-group">-->
            <!--                            <label for>Phone</label>-->
            <!--                            <input-->
            <!--                                    type="number"-->
            <!--                                    academic-class="form-control border border-dark"-->
            <!--                                    placeholder="Phone"-->
            <!--                                    v-model="user.phone"-->
            <!--                            />-->
            <!--                            &lt;!&ndash; <span v-if="$v.user.address.$error" academic-class="text-danger">Address is required.</span> &ndash;&gt;-->
            <!--                        </div>-->
            <!--                        <div academic-class="col-4 form-group">-->
            <!--                            <label for>Mobile</label>-->
            <!--                            <input-->
            <!--                                    type="number"-->
            <!--                                    academic-class="form-control border border-dark"-->
            <!--                                    placeholder="Mobile"-->
            <!--                                    v-model="user.mobile"-->
            <!--                            />-->
            <!--                            <span v-if="$v.user.mobile.$error" academic-class="text-danger">Provide mobile Number</span>-->
            <!--                        </div>-->


            <!--                        <div academic-class="col-12 text-right">-->
            <!--                            <button academic-class="btn btn-primary" @click="saveUser()">-->
            <!--                                Save-->
            <!--                            </button>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <div class="row">
              <v-col cols="12" md="4" class="card p-10 mt-10">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <h3>Setup MFA  Authenticator</h3>
                      <p>Use multi-factor authentication (MFA) to add an extra layer of security to your account.</p>
                    </div>
                    <div class="col-md-4 text-right">
                      <p>
                        <img
                            src="https://cdn2.iconfinder.com/data/icons/plex-for-android/96/authenticator.png"
                            style="max-width: 64px"
                        />
                      </p>
                    </div>
                    <v-btn
                        v-if="!this.user.is_mfa_enabled"
                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                        class="btn btn-primary text-white"
                        text
                        :loading="isMfaAuthLoading"
                        @click="getMfaAuthenticatorCode"
                    >Setup MFA Authenticator</v-btn>
                    <v-btn
                        v-else
                        color="white"
                        class="bg-danger"
                        text
                        :loading="isMfaAuthLoading"
                        @click="deActivateMfaAuthenticator"
                    >Deactivate MFA Authenticator</v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" class="card p-10 mt-10">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <h3>Setup MFA - Email Verification</h3>
                      <p>Use multi-factor authentication (MFA) to add an extra layer of security to your account.</p>
                    </div>
                    <div class="col-md-4 text-right">
                      <p>
                        <img
                            src="https://img.icons8.com/plasticine/100/000000/important-mail.png"
                            style="max-width: 64px"
                        />
                      </p>
                    </div>
                    <v-btn
                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                        class="btn btn-primary text-white"
                        text
                        v-if="!this.user.is_email_authentication_enabled"
                        @click="getEmailAuthenticator"
                    >Setup Email Authenticator</v-btn>

                    <v-btn
                        color="white"
                        class="bg-danger"
                        text
                        v-else
                        @click="deactivateEmailAuthenticator"
                    >Deactivate Email Authenticator</v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" class="card p-10 mt-10">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <h3>Password</h3>
                      <p>Setup secure password to access and manage your account.</p>
                    </div>
                    <div class="col-md-4 text-right">
                      <p>
                        <img
                            src="https://img.icons8.com/plasticine/100/000000/password.png"
                            style="max-width: 64px"
                        />
                      </p>
                    </div>
                    <v-btn
                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                        class="btn btn-primary text-white"
                        text
                        @click="showChangePasswordDialog"
                    >Change Password</v-btn>
                  </div>
                </div>
              </v-col>
            </div>
          </div>
        </div>
      </div>
      <v-dialog
          v-model="mfaAuthenticatorSetup"
          :loading="isMfaAuthLoading"
          persistent
          max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Setup MFA Authenticator</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12">
                    <ul class="mfa-instructions">
                      <li>Get the MFA Authenticator App from the Apple AppStore or Google PlayStore</li>
                      <li>In the app, select account setup</li>
                      <li>Choose to Scan Barcode, then scan code below</li>
                    </ul>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-app id="inspire">
                      <v-row align="center" justify="center">
                        <v-img
                            :src="authenticator.mfa.image_url"
                            :lazy-src="`https://picsum.photos/200/200?random`"
                            max-width="200"
                            max-height="200"
                            aspect-ratio="1"
                            class="grey lighten-2"
                        >
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-row>
                    </v-app>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <a href="#" @click="mfaSecret= !mfaSecret">or add your key manually</a>
                    <v-row class="mfa-secret-key" v-if="mfaSecret">
                      <v-col cols="12">
                        <p>
                          <b>Account:</b>
                          {{authenticator.mfa.account}}
                        </p>
                        <p>
                          <b>Secret Key:</b>
                          {{authenticator.mfa.secret_key}}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                        label="Enter Verification Code displayed"
                        v-model="authenticator.mfa.verification_code"
                        required
                        dense
                        outlied
                    ></v-text-field>
                    <p
                        class="text-danger font-weight-bold"
                        v-if="$v.authenticator.mfa.verification_code.$error"
                    >Please enter a verification code</p>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeMfaAuthenticatorSetup">Close</v-btn>
            <v-btn
                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                class="btn-save-popup"
                text
                @click.prevent="activateMfaAuthenticator"
            >Save & Activate</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="emailAuthenticationSetup" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Setup Email Authenticator</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12">
                    <ul class="mfa-instructions">
                      <li>Setup your email and add an extra layer of security to your account.</li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                :loading="loading"
                text
                @click="closeEmailAuthenticatorDialog"
            >Close</v-btn>
            <v-btn
                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                class="btn-save-popup"
                text
                :loading="isEmailAuthLoading"
                @click.once="activateEmailAuthenticator"
            >Activate</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="changePasswordDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Change Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12">
                    <ul class="mfa-instructions">
                      <li>You will receive an email to reset your password.</li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                :loading="loading"
                text
                @click="closeChangePasswordDialog"
            >Close</v-btn>
            <v-btn
                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                class="btn-save-popup"
                text
                :loading="isChangePasswordLoading"
                @click.once="sendPasswordResetEmail"
                :disabled="emailVerificationMailRequested"
            >{{emailVerificationMailRequested ? 'Please wait for a minute' :'Request Email'}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import {email, minLength, numeric, required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Apiservice from '@/core/services/api.service';
const userService = new UserService();

export default {
  name: "user",
  validations: {
    user: {
      first_name: {required},
      last_name: {required},
      phone: {minLength: minLength(10)},
      email: {email},
      mobile: {numeric, minLength: minLength(10)},
    },
    authenticator: {
      mfa: {
        verification_code: { required }
      }
    }
  },
  components: {},
  data() {
    return {
      file1: null,
      file2: null,
      edit: false,
      isMfaAuthLoading: false,
      authenticator: {
        mfa: {
          verification_code: "",
          image_url: "",
          account: "",
          secret_key: ""
        }
      },
      user: {
        phone: null,
        email: null,
        first_name: null,
        last_name: null,
        mobile: null,

      },
      showMobileInput: true,
      isEmailAuthLoading: false,
      isChangePasswordLoading: false,
      emailVerificationMailRequested: false,
      isBusy: false,
      mobileRecoverySetup: false,
      mobileNo: null,
      mfaAuthenticatorSetup: false,
      emailAuthenticationSetup: false,
      changePasswordDialog: false,
      mfaSecret: false,
      seoDialog: false,
      loading: false,
      page: false
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getMfaAuthenticatorCode() {
      this.isMfaAuthLoading = true;
      userService.requestMfaAuthenticator().then(res => {
        this.authenticator.mfa.account = res.data.account;
        this.authenticator.mfa.secret_key = res.data.secret_key;
        this.authenticator.mfa.image_url = res.data.image_url;
        this.mfaAuthenticatorSetup = true;
        this.isMfaAuthLoading = false;
      });
    },
    closeMfaAuthenticatorSetup() {
      this.mfaAuthenticatorSetup = false;
      this.authenticator.mfa.account = "";
      this.authenticator.mfa.secret_key = "";
      this.authenticator.mfa.image_url = "";
    },
    activateMfaAuthenticator() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.authenticator.mfa.$error) {
      } else {
        userService.activateMfaAuthenticator(this.authenticator.mfa).then(
            res => {
              this.authenticator.mfa = {
                verification_code: "",
                image_url: "",
                account: "",
                secret_key: ""
              };
              this.isMfaAuthLoading = false;
              this.loading = false;
              if (res.data.status == "OK") {
                this.mfaAuthenticatorSetup = false;
                this.$snotify.success("MFA authenticator activated");
                this.getUser();
              } else this.$snotify.error("Problem occurred");
            }
        );
      }
    },

    deActivateMfaAuthenticator() {
      this.isMfaAuthLoading = true;
      userService.deActivateMfaAuthenticator().then(res => {
        this.isMfaAuthLoading = false;
        if (res.data.status == "OK") {
          this.user.is_mfa_enabled = false;
          this.$snotify.success("Google authenticator Deactivated");
          this.getUser();
        }
      });
    },
    activateEmailAuthenticator() {
      this.isEmailAuthLoading = true;
      this.emailAuthenticationSetup = false;
      this.user.is_email_authentication_enabled = true;
      userService.activateEmailVerificationCode().then(response => {
        this.$snotify.success("Email authenticator activated");
        this.isEmailAuthLoading = false;
        this.emailAuthenticationSetup = false;
        this.getUser();
      });

    },

    deactivateEmailAuthenticator() {
      this.isEmailAuthLoading = true;
      this.emailAuthenticationSetup = false;
      this.user.is_email_authentication_enabled = false;
      userService.deActivateEmailVerificationCode().then(response => {
        this.$snotify.success("Email authenticator deactivated");
        this.isEmailAuthLoading = false;
        this.emailAuthenticationSetup = false;
        this.user.is_email_authentication_enabled = false;
        this.getUser();
      });

    },
    getEmailAuthenticator() {
      this.emailAuthenticationSetup = true;
    },
    sendPasswordResetEmail() {
      Apiservice.post("admin/reset/password", {
        email: this.user.email
      })
          .then(response => {
            this.closeChangePasswordDialog();
            this.emailVerificationMailRequested = true;
            this.$snotify.success("Password reset email sent");
          })
          .catch(({ response }) => {
            this.emailVerificationMailRequested = false;
            this.$snotify.error("Problem occured");
          });

      setTimeout(() => {
        this.emailVerificationMailRequested = false;
      }, 60000);
    },
    closeEmailAuthenticatorDialog() {
      this.emailAuthenticationSetup = false;
    },
    closeChangePasswordDialog() {
      this.changePasswordDialog = false;
    },
    showChangePasswordDialog() {
      this.changePasswordDialog = true;
    },
    getUser() {
      userService.show(this.currentUser.id).then(response => {
        this.user = response.data.user;
        if (this.user && this.user.id) {
          this.edit = true;
        }
      });
    },
    saveUser() {
      this.$v.user.$touch();
      if (this.$v.user.$error) {
        setTimeout(() => {
          this.$v.user.$reset();
        }, 3000);
      } else {
        this.updateUser();
      }
    },
    createSetting() {
      userService.store(this.user).then(() => {
        this.$snotify.success("Updated successfully");
        this.getUser();
      });
    },
    updateUser() {
      userService.update(this.user.id, this.user).then(response => {
        this.$snotify.success("Updated successfully");
        this.getUser();
      });
    },

    generateFd() {
      let fd = new FormData();
      for (let key in this.user) {
        if (
            key == "logo" &&
            this.user["logo"] &&
            this.user["logo"] != null
        ) {
          fd.append("logo", this.user[key]);
        } else if (
            key == "fav_icon" &&
            this.user["fav_icon"] &&
            this.user["fav_icon"] != null
        ) {
          fd.append("fav_icon", this.user[key]);
        }
        {
          fd.append(key, this.user[key] ? this.user[key] : "");
        }
      }
      return fd
    },

    submit() {
      Swal.fire({
        title: "",
        text: "Site user updated",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    }
  }
};
</script>
